// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var DeccoUtils$SmartcutsMacros = require("../../bs-utils/src/DeccoUtils.bs.js");

var _map = {"SUPERADMIN":"SUPERADMIN","CUSTOMER":"CUSTOMER","WAITLIST":"WAITLIST","REVIEWER":"REVIEWER"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var UserRole = DeccoUtils$SmartcutsMacros.MakePolymorphicWithDeccoFns({
      typeName: "User.UserRole.t",
      tToJs: tToJs,
      tFromJs: tFromJs
    });

var _map$1 = {"CANARY":"CANARY"};

function tToJs$1(param) {
  return param;
}

function tFromJs$1(param) {
  return _map$1[param];
}

var ExtensionBuild = DeccoUtils$SmartcutsMacros.MakePolymorphicWithDeccoFns({
      typeName: "User.ExtensionBuild.t",
      tToJs: tToJs$1,
      tFromJs: tFromJs$1
    });

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "token",
                Decco.stringToJson(v.token)
              ],
              [
                "userId",
                Decco.stringToJson(v.userId)
              ],
              [
                "email",
                Decco.stringToJson(v.email)
              ],
              [
                "role",
                Curry._1(UserRole.t_encode, v.role)
              ],
              [
                "build",
                Decco.optionToJson(ExtensionBuild.t_encode, v.build)
              ],
              [
                "finishedOnboarding",
                Decco.boolToJson(v.finishedOnboarding)
              ],
              [
                "userFlags",
                Decco.arrayToJson(Decco.stringToJson, v.userFlags)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var token = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "token"), null));
  if (token.TAG === /* Ok */0) {
    var userId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "userId"), null));
    if (userId.TAG === /* Ok */0) {
      var email = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "email"), null));
      if (email.TAG === /* Ok */0) {
        var role = Curry._1(UserRole.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "role"), null));
        if (role.TAG === /* Ok */0) {
          var build = Decco.optionFromJson(ExtensionBuild.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "build"), null));
          if (build.TAG === /* Ok */0) {
            var finishedOnboarding = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "finishedOnboarding"), null));
            if (finishedOnboarding.TAG === /* Ok */0) {
              var userFlags = Decco.arrayFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "userFlags"), null));
              if (userFlags.TAG === /* Ok */0) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          token: token._0,
                          userId: userId._0,
                          email: email._0,
                          role: role._0,
                          build: build._0,
                          finishedOnboarding: finishedOnboarding._0,
                          userFlags: userFlags._0
                        }
                      };
              }
              var e = userFlags._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".userFlags" + e.path,
                        message: e.message,
                        value: e.value
                      }
                    };
            }
            var e$1 = finishedOnboarding._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".finishedOnboarding" + e$1.path,
                      message: e$1.message,
                      value: e$1.value
                    }
                  };
          }
          var e$2 = build._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".build" + e$2.path,
                    message: e$2.message,
                    value: e$2.value
                  }
                };
        }
        var e$3 = role._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".role" + e$3.path,
                  message: e$3.message,
                  value: e$3.value
                }
              };
      }
      var e$4 = email._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".email" + e$4.path,
                message: e$4.message,
                value: e$4.value
              }
            };
    }
    var e$5 = userId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".userId" + e$5.path,
              message: e$5.message,
              value: e$5.value
            }
          };
  }
  var e$6 = token._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".token" + e$6.path,
            message: e$6.message,
            value: e$6.value
          }
        };
}

exports.UserRole = UserRole;
exports.ExtensionBuild = ExtensionBuild;
exports.t_encode = t_encode;
exports.t_decode = t_decode;
/* UserRole Not a pure module */
