// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");

var creds = Curry._1(Css.style, {
      hd: Css.maxWidth({
            NAME: "px",
            VAL: 340
          }),
      tl: {
        hd: Css.margin2("zero", "auto"),
        tl: /* [] */0
      }
    });

var form = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("column"),
        tl: /* [] */0
      }
    });

var label = Curry._1(Css.style, {
      hd: Css.fontWeight({
            NAME: "num",
            VAL: 600
          }),
      tl: /* [] */0
    });

var input = Curry._1(Css.style, {
      hd: Css.padding({
            NAME: "px",
            VAL: 8
          }),
      tl: {
        hd: Css.margin3({
              NAME: "rem",
              VAL: 0.3
            }, "zero", {
              NAME: "rem",
              VAL: 1.0
            }),
        tl: {
          hd: Css.border({
                NAME: "px",
                VAL: 1
              }, "solid", Css.hex("ccc")),
          tl: {
            hd: Css.borderRadius({
                  NAME: "px",
                  VAL: 4
                }),
            tl: /* [] */0
          }
        }
      }
    });

var error = Curry._1(Css.style, {
      hd: Css.margin3({
            NAME: "rem",
            VAL: 0.5
          }, "zero", "zero"),
      tl: {
        hd: Css.color(Css.hex("f00")),
        tl: {
          hd: Css.minHeight(Css.px(25)),
          tl: /* [] */0
        }
      }
    });

exports.creds = creds;
exports.form = form;
exports.label = label;
exports.input = input;
exports.error = error;
/* creds Not a pure module */
