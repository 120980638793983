// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Head = require("next/head").default;
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Router = require("next/router");
var User$SmartcutsMacros = require("../packages/bs-macros-extension/src/User.bs.js");
var ReactUtils$SmartcutsMacros = require("../packages/bs-utils/src/ReactUtils.bs.js");
var StyleCreds$SmartcutsMacros = require("../src/common/StyleCreds.bs.js");
var OptionUtils$SmartcutsMacros = require("../packages/bs-utils/src/OptionUtils.bs.js");
var StylePublic$SmartcutsMacros = require("../src/common/StylePublic.bs.js");
var LayoutPublic$SmartcutsMacros = require("../src/components/LayoutPublic.bs.js");
var JsPromiseErrorUtils$SmartcutsMacros = require("../packages/bs-utils/src/JsPromiseErrorUtils.bs.js");

var SignInParams = {};

var SignInResponse = {};

function signIn(email, password) {
  return fetch("/api/login", Fetch.RequestInit.make(/* Post */2, {
                      "Content-Type": "application/json"
                    }, Caml_option.some(JSON.stringify({
                              email: email,
                              password: password
                            })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (res) {
              var match = res.status;
              if (match !== 200) {
                return res.text().then(Js_exn.raiseError);
              } else {
                return res.json().then(function (json) {
                            return Promise.resolve(json);
                          });
              }
            });
}

function Login(Props) {
  var match = React.useState(function () {
        return {
                email: "",
                password: "",
                status: /* CredsNotSubmitted */0
              };
      });
  var setUserData = match[1];
  var userData = match[0];
  var router = Router.useRouter();
  var arg = Belt_Option.map(Js_dict.get(router.query, "redirect"), (function (prim) {
          return decodeURIComponent(prim);
        }));
  var redirect = (function (param) {
        return OptionUtils$SmartcutsMacros.withDefault(param, arg);
      })("/app");
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    Curry._1(setUserData, (function (userData) {
            return {
                    email: userData.email,
                    password: userData.password,
                    status: /* SubmittingCreds */1
                  };
          }));
    signIn(userData.email, userData.password).then(function (param) {
            var match = OptionUtils$SmartcutsMacros.andThen(User$SmartcutsMacros.ExtensionBuild.tFromJs, param.build);
            var url = match !== undefined ? process.env.NEXT_PUBLIC_CANARY_ORIGIN + redirect : redirect;
            router.push(url, undefined, undefined);
            return Promise.resolve(undefined);
          }).catch(function (err) {
          console.error(err);
          var jsExn = Curry._1(JsPromiseErrorUtils$SmartcutsMacros.JsPromiseError.getErrorType, err);
          var exit = 0;
          if (typeof jsExn === "number") {
            if (jsExn === /* NotFound */0) {
              Curry._1(setUserData, (function (userData) {
                      return {
                              email: userData.email,
                              password: userData.password,
                              status: /* LoginError */{
                                _0: "Not found"
                              }
                            };
                    }));
            } else {
              exit = 1;
            }
          } else {
            switch (jsExn.TAG | 0) {
              case /* JsExn */0 :
                  var jsExn$1 = jsExn._0;
                  Curry._1(setUserData, (function (userData) {
                          var arg = OptionUtils$SmartcutsMacros.map(jsExn$1.message, (function (msg) {
                                  if (msg === "authentication failed") {
                                    return "Wrong email or password";
                                  } else {
                                    return msg;
                                  }
                                }));
                          return {
                                  email: userData.email,
                                  password: userData.password,
                                  status: /* LoginError */{
                                    _0: (function (param) {
                                          return OptionUtils$SmartcutsMacros.withDefault(param, arg);
                                        })("An error occurred.")
                                  }
                                };
                        }));
                  break;
              case /* InvalidArgument */3 :
                  var arg = jsExn._0;
                  Curry._1(setUserData, (function (userData) {
                          return {
                                  email: userData.email,
                                  password: userData.password,
                                  status: /* LoginError */{
                                    _0: "Invalid arguments: " + arg
                                  }
                                };
                        }));
                  break;
              case /* SysError */4 :
                  var msg = jsExn._0;
                  Curry._1(setUserData, (function (userData) {
                          return {
                                  email: userData.email,
                                  password: userData.password,
                                  status: /* LoginError */{
                                    _0: "System error: " + msg
                                  }
                                };
                        }));
                  break;
              case /* UnknownPromiseError */13 :
                  exit = 1;
                  break;
              default:
                exit = 1;
            }
          }
          if (exit === 1) {
            Curry._1(setUserData, (function (userData) {
                    return {
                            email: userData.email,
                            password: userData.password,
                            status: /* LoginError */{
                              _0: "Unknown error"
                            }
                          };
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  var match$1 = userData.status;
  var match$2 = userData.status;
  var error = userData.status;
  return React.createElement(LayoutPublic$SmartcutsMacros.make, {
              onClickCallToAction: (function (param) {
                  router.push("/", undefined, undefined);
                  
                }),
              children: null
            }, React.createElement(Head, {
                  children: React.createElement("title", undefined, ReactUtils$SmartcutsMacros.s("Login | Smartcuts"))
                }), React.createElement("div", {
                  className: StyleCreds$SmartcutsMacros.creds
                }, React.createElement("form", {
                      className: StyleCreds$SmartcutsMacros.form,
                      onSubmit: handleSubmit
                    }, React.createElement("input", {
                          className: StylePublic$SmartcutsMacros.textInput + (" " + Curry._1(Css.style, {
                                  hd: Css.width(Css.px(282)),
                                  tl: /* [] */0
                                })),
                          id: "email",
                          name: "email",
                          placeholder: "Email",
                          type: "email",
                          value: userData.email,
                          onChange: (function ($$event) {
                              var email = $$event.target.value;
                              return Curry._1(setUserData, (function (userData) {
                                            return {
                                                    email: email,
                                                    password: userData.password,
                                                    status: userData.status
                                                  };
                                          }));
                            })
                        }), React.createElement("input", {
                          className: StylePublic$SmartcutsMacros.textInput + (" " + Curry._1(Css.style, {
                                  hd: Css.width(Css.px(282)),
                                  tl: /* [] */0
                                })),
                          id: "password",
                          name: "password",
                          placeholder: "Password",
                          type: "password",
                          value: userData.password,
                          onChange: (function ($$event) {
                              var password = $$event.target.value;
                              return Curry._1(setUserData, (function (userData) {
                                            return {
                                                    email: userData.email,
                                                    password: password,
                                                    status: userData.status
                                                  };
                                          }));
                            })
                        }), React.createElement("button", {
                          className: StylePublic$SmartcutsMacros.button,
                          disabled: typeof match$1 === "number" ? match$1 !== 0 : false,
                          type: "submit"
                        }, typeof match$2 === "number" && match$2 !== 0 ? ReactUtils$SmartcutsMacros.s("Logging in") : ReactUtils$SmartcutsMacros.s("Login")), typeof error === "number" ? React.createElement("p", {
                            className: StyleCreds$SmartcutsMacros.error
                          }, ReactUtils$SmartcutsMacros.s(" ")) : React.createElement("p", {
                            className: StyleCreds$SmartcutsMacros.error
                          }, ReactUtils$SmartcutsMacros.s(error._0))), React.createElement("p", undefined, ReactUtils$SmartcutsMacros.s("Forgot your password? "), React.createElement("a", {
                          href: "/forgot_password"
                        }, ReactUtils$SmartcutsMacros.s("Reset my password")))));
}

var make = Login;

var $$default = Login;

exports.SignInParams = SignInParams;
exports.SignInResponse = SignInResponse;
exports.signIn = signIn;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* Css Not a pure module */
